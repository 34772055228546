import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import SimpleReactLightbox from "simple-react-lightbox";
import theme from "assets/theme/theme.js";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/plugins/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";
//layouts
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import ManagementLayout from "layouts/Management";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "redux/store/store";
import SetupAxios from "utils/setup_axios";
import messages_pt from "lang/compiled/pt.json";

export const INTERNAL_API_URL = process.env.REACT_APP_INTERNAL_API_URL;
export const EXTERNAL_API_URL = process.env.REACT_APP_EXTERNAL_API_URL;
export const INEFOP_BO_URL = process.env.REACT_APP_INEFOP_BO_URL;

const messages = {
  pt: messages_pt,
};
const language = "pt";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <SimpleReactLightbox>
          <IntlProvider locale={language} messages={messages[language]}>
            <BrowserRouter>
              <SetupAxios store={store}>
                <Switch>
                  {/* <Route path="/index" render={(props) => <Index {...props} />} /> */}
                  <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                  <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                  <Route path="/management" render={(props) => <ManagementLayout {...props} />} />
                  <Redirect from="*" to="/auth/index" />
                </Switch>
              </SetupAxios>
            </BrowserRouter>
          </IntlProvider>
        </SimpleReactLightbox>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.querySelector("#root")
);
