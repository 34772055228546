import { PROFESSIONAL_KITS, STOCKS } from "utils/actionsConsts";

const stockReducer = (
  state = {
    categoriesUnitValue: [],
    searchProfessionalKitsResult: [],
    searchStocksResult: [],
    RegisterLossBreakModal: {
      show: false,
      category: null,
      batchNumber: null,
    },
    RegisterInWareHouseModal: {
      show: false,
      category: null,
      batchNumber: null,
    },
    DeliverKitModal: {
      show: false,
      category: null,
      batchNumber: null,
    },
    characterizeProfessionalKit_loading: false,
    searchProfessionalKit_loading: false,
    searchStocks_loading: false,
    get_stocks_detail_loading: false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case "characterizeProfessionalKit":
      return {
        ...state,
        characterizationResult: action.payload,
        showCharacterizationResultModal: true,
        characterizeProfessionalKit_loading: false,
      };
    case "updateProfessionalKit":
      return {
        ...state,
        characterizationResult: action.payload,
        showCharacterizationResultModal: true,
      };
    case "updateProfessionalKitState":
      return { ...state, characterizationResult: action.payload };
    case "getProfessionalKitDetails":
      return { ...state, professionalKitDetails: action.payload };
    case "getStockDetails":
      return { ...state, stockDetails: action.payload };
    case "getKitDeliveryApplicationDetails":
      return { ...state, kitDeliveryDetails: action.payload };
    case "hideKitCharacterizationResultModal":
      return { ...state, showCharacterizationResultModal: action.payload };
    case "searchProfessionalKits":
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case "searchStocks":
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case "getBatchValue":
      return { ...state, batchValue: action.payload.batchValue };
    case "registerStockInWarehouse":
    case "registerLossOrBreak":
      return {
        ...state,
        registerInWarehouseResult: action.payload,
        showRegisterStockInWarehouseResultModal: true,
      };
    case "hideRegisterStockInWarehouseResultModal":
      return {
        ...state,
        showRegisterStockInWarehouseResultModal: action.payload,
      };
    case "getUnitValueByCategory":
      return {
        ...state,
        categoriesUnitValue:
          state.categoriesUnitValue === undefined
            ? [
              {
                id: action.payload.categoryId,
                unitValue: action.payload.unitValue,
              },
            ]
            : state.categoriesUnitValue.some(
              (category) => category.id === action.payload.categoryId
            )
              ? state.categoriesUnitValue.map((p) =>
                p.id === action.payload.categoryId
                  ? { ...p, unitValue: action.payload.unitValue }
                  : p
              )
              : state.categoriesUnitValue.push({
                id: action.payload.categoryId,
                unitValue: action.payload.unitValue,
              }) > 0
                ? state.categoriesUnitValue
                : state.categoriesUnitValue,
      };
    case "showRegisterLossBreakModal":
      return {
        ...state,
        RegisterLossBreakModal: {
          show: action.payload.toShow,
          category: action.payload.category,
          batchNumber: action.payload.batchNumber,
          province: action.payload.province,
          warehouse: action.payload.warehouse,
          onCloseAction: action.payload.onCloseAction
        },
      };
    case "showRegisterInWareHouseModal":
      return {
        ...state,
        RegisterInWareHouseModal: {
          show: action.payload.toShow,
          category: action.payload.category,
          province: action.payload.province,
          warehouse: action.payload.warehouse
        },
      };
    case "showDeliverKitModal":
      return {
        ...state,
        DeliverKitModal: {
          show: action.payload.toShow,
          category: action.payload.category,
        },
      };
    case "characterizeProfessionalKit_loading":
      return { ...state, characterizeProfessionalKit_loading: true };
    case "updateProfessionalKit_loading":
      return { ...state, updateProfessionalKit_loading: action.payload };
    case PROFESSIONAL_KITS.SEARCH_LOADING:
      return { ...state, searchProfessionalKit_loading: action.payload };
    case PROFESSIONAL_KITS.GET_DETAILS_LOADING:
      return { ...state, getProfessionalKitDetails_loading: action.payload };
    case STOCKS.SEARCH_LOADING:
      return { ...state, searchStocks_loading: action.payload };
    case STOCKS.GET_STOCK_DETAILS_LOADING:
      return { ...state, get_stocks_detail_loading: action.payload };
    default:
      return state;
  }
};

export default stockReducer;