//compile the other user reducer into this one!
import { USERS } from "utils/actionsConsts";

const usersReducer = (
  state = {
    backoffice_users_search_loading: false,
    frontoffice_users_search_loading: false,
    register_fo_user_loading:false,
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case USERS.GET_BACKOFFICE_FILTERED_SEARCH:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case USERS.GET_FRONTOFFICE_FILTERED_SEARCH:
      return {
        ...state,
        result: action.payload.data,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case USERS.GET_BACKOFFICE_FILTERED_SEARCH_LOADING:
      return {
        ...state,
        backoffice_users_search_loading: action.payload,
        result: action.payload ? [] : state.result,
        total_records: action.payload ? 0 : state.total_records,
      };
    case USERS.GET_FRONTOFFICE_FILTERED_SEARCH_LOADING:
      return {
        ...state,
        frontoffice_users_search_loading: action.payload,
        result: action.payload ? [] : state.result,
        total_records: action.payload ? 0 : state.total_records,
      };
    case USERS.REGISTER_FO_USER_LOADING:
      return { ...state, register_fo_user_loading: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
