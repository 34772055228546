import { SCHEDULING } from "utils/actionsConsts";

const schedulingReducer = (
  state = {
    create_loading: false,
    schedule_detail: {},
    search_loading: false,
    schedule_detail_loading: false,
    result: [],
    total_records: 0,
    total_pages: 0,
  },
  action
) => {
  switch (action.type) {
    case SCHEDULING.CREATE_LOADING:
      return { ...state, create_loading: action.payload };
    case SCHEDULING.SEARCH:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case SCHEDULING.SEARCH_LOADING:
      return { ...state, search_loading: action.payload };
    case SCHEDULING.GET:
      return { ...state, schedule_detail: action.payload };
    case SCHEDULING.GET_LOADING:
      return { ...state, schedule_detail_loading: action.payload };
    case SCHEDULING.UPDATE_LOADING:
      return { ...state, schedule_update_loading: action.payload };
    case SCHEDULING.CLEAR_DETAIL:
      return { ...state, schedule_detail: {} };
    default:
      return state;
  }
};

export default schedulingReducer;
