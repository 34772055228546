import { DASHBOARD_ACTIONS } from "utils/actionsConsts";

const dashboardReducer = (
  state = {
    dashboardStockInfo: [],
    dashboardNotificationsInfo: [],
    dashboardNotificationsCount: 0,
    dashboardNewCompaniesInfo: [],
    dashboardNewCompaniesCount: 0,
    dashboardOnGoingApplicationsInfo: [],
    dashboardOnGoingApplicationsCount: 0,
    dashboardOpportunitiesInfo: [],
    dashboardOpportunitiesCount: 0,
  },
  action
) => {
  switch (action.type) {
    case "getDashboardStockInfo":
      return { ...state, dashboardStockInfo: action.payload };
    case "getDashboardNotificationsInfo":
      return { ...state, dashboardNotificationsInfo: action.payload };
    case "getDashboardUnreadNotificationsCountInfo":
      return { ...state, dashboardNotificationsCount: action.payload };
    case "getDashboardNewCompaniesInfo":
      return {
        ...state,
        dashboardNewCompaniesInfo: action.payload.list,
        dashboardNewCompaniesCount: action.payload.count,
      };
    case "getDashboardOpportunities":
      return {
        ...state,
        dashboardOpportunitiesInfo: action.payload.list,
        dashboardOpportunitiesCount: action.payload.count,
      };

    case "getDashboardOnGoingApplicationsInfo":
      return {
        ...state,
        dashboardOnGoingApplicationsInfo: action.payload.list,
        dashboardOnGoingApplicationsCount: action.payload.count,
      };
    case DASHBOARD_ACTIONS.GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS:
      return {
        ...state,
        dashboardFollowUpsWithUnapprovedReportsInfo: action.payload,
      };
    case DASHBOARD_ACTIONS.GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_LOADING:
      return { ...state, dashboardFollowUpsWithUnapprovedReportsInfo_loading: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;