import { MENTORING } from "utils/actionsConsts";

const mentoringReducer = (
  state = {
    searchMentors_loading: false,
    mentors_result: [],
    mentors_refresh_results: false,
    mentors_total_records: 0,
    mentors_total_pages: 0,
    getMentorDetails_loading: false,
    mentorDetails: [],
    getMenteeMentorDetails_loading: false,
    menteeMentorDetails: [],
    menteeMentor_refresh_results: false,
    mentorApproveLoading: false,

    searchMentees_loading: false,
    mentees_result: [],
    mentees_refresh_results: false,
    mentees_total_records: 0,
    mentees_total_pages: 0,
    getMenteeDetails_loading: false,
    menteeDetails: [],
    getMenteesByMentor_loading: false,
    menteesByMentor_result: [],
    menteesByMentor_total_records: 0,
    menteesByMentor_total_pages: 0,
    menteesByMentor_refresh_results: false,

    mentoringAssign_loading: false,
    mentoringDisassociationLoading: false,
    mentoringRejectCancelLoading: false,
  },
  action
) => {
  switch (action.type) {
    /* General Mentoring */
    case MENTORING.MENTORING_ASSIGN_LOADING:
      return { ...state, mentoringAssign_loading: action.payload };
    case MENTORING.MENTORING_DISASSOCIATION_LOADING:
      return { ...state, mentoringDisassociationLoading: action.payload };
    case MENTORING.MENTORING_REJECT_CANCEL_LOADING:
      return { ...state, mentoringRejectCancelLoading: action.payload };
     
    /* Mentors */
    case MENTORING.GET_FILTERED_MENTORS_LOADING:
      return { ...state, searchMentors_loading: action.payload };
    case MENTORING.GET_FILTERED_MENTORS:
      return {
        ...state,
        mentors_result: action.payload,
        mentors_total_records: action.payload.total_records,
        mentors_total_pages: action.payload.total_pages,
      };
    case MENTORING.MENTORS_REFRESH_RESULTS:
      return { ...state, mentors_refresh_results: action.payload };
    case MENTORING.GET_MENTOR_INFO_LOADING:
      return { ...state, getMentorDetails_loading: action.payload };
    case MENTORING.GET_MENTOR_INFO:
      return { ...state, mentorDetails: action.payload };
    case MENTORING.GET_MENTOR_BY_MENTEE_INFO_LOADING:
      return { ...state, getMenteeMentorDetails_loading: action.payload };
    case MENTORING.GET_MENTOR_BY_MENTEE_INFO:
      return { ...state, menteeMentorDetails: action.payload };
    case MENTORING.MENTOR_BY_MENTEE_REFRESH_RESULTS:
      return { ...state, menteeMentor_refresh_results: action.payload };
      case MENTORING.MENTOR_APPROVE_LOADING:
        return { ...state, mentorApproveLoading: action.payload };
       
    /* Mentees */
    case MENTORING.GET_FILTERED_MENTEES_LOADING:
      return { ...state, searchMentees_loading: action.payload };
    case MENTORING.GET_FILTERED_MENTEES:
      return {
        ...state,
        mentees_result: action.payload,
        mentees_total_records: action.payload.total_records,
        mentees_total_pages: action.payload.total_pages,
      };
    case MENTORING.MENTEES_REFRESH_RESULTS:
      return { ...state, mentees_refresh_results: action.payload };
    case MENTORING.GET_MENTEE_INFO_LOADING:
      return { ...state, getMenteeDetails_loading: action.payload };
    case MENTORING.GET_MENTEE_INFO:
      return { ...state, menteeDetails: action.payload };
    case MENTORING.GET_MENTEES_BY_MENTOR_LOADING:
      return { ...state, getMenteesByMentor_loading: action.payload };
    case MENTORING.GET_MENTEES_BY_MENTOR:
      return {
        ...state,
        menteesByMentor_result: action.payload,
        menteesByMentor_total_records: action.payload.total_records,
        menteesByMentor_total_pages: action.payload.total_pages,
      };
    case MENTORING.MENTEES_BY_MENTOR_REFRESH_RESULTS:
      return { ...state, menteesByMentor_refresh_results: action.payload };
    default:
      return state;
  }
};

export default mentoringReducer;
