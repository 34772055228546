import { REPORTS } from "utils/actionsConsts";

const reportsReducer = (
  state = {
    get_report_loading: false,
  },
  action
) => {
  switch (action.type) {
    case REPORTS.GET_REPORT:
      return {
        ...state,
        result: action.payload,
      };
    case REPORTS.GET_REPORT_LOADING:
      return { ...state, get_report_loading: action.payload };
    default:
      return state;
  }
};

export default reportsReducer;
