import { defineMessages } from "react-intl";

export const errorMessages = defineMessages({
  ErrorMessage_FieldRequired: {
    id: "ErrorMessage_FieldRequired",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem de erro quando um campo obrigatório do formulário não é preenchido",
  },
  ErrorMessage_FieldPercentage: {
    id: "ErrorMessage_FieldPercentage",
    defaultMessage: "O valor mínimo da % deve ser maior que 0 e menor que 100.",
    description: "Mensagem de erro quando ocorre um erro a % é incorrecta",
  },
  ErrorMessage_GenericError: {
    id: "ErrorMessage_GenericError",
    defaultMessage: "Ocorreu um erro a efetuar o seu pedido, por favor tente mais tarde.",
    description: "Mensagem de erro quando ocorre um erro genérico na API",
  },
  ErrorMessage_InvalidFormat: {
    id: "ErrorMessage_InvalidFormat",
    defaultMessage: "Formato inválido",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
  ErrorMessage_Invalid_Value: {
    id: "ErrorMessage_Invalid_Value",
    defaultMessage: "Valor inválido.",
    description: "Mensagem de erro quando o valor inserido é inválido.",
  },
  ErrorMessage_User_Colective_NIF_Invalid_Format: {
    id: "ErrorMessage_User_Colective_NIF_Invalid_Format",
    defaultMessage: "O NIF deve ter 10 digitos",
    description: "Mensagem de erro quando o valor inserido no campo NIF tem um formato inválido.",
  },
  ErrorMessage_User_Colective_PermitCode_Invalid_Format: {
    id: "ErrorMessage_User_Colective_PermitCode_Invalid_Format",
    defaultMessage: "O código de alvará tem tamanho máximo de 12 caracteres",
    description: "Mensagem de erro quando o valor inserido no campo Código de Alvará tem um formato inválido.",
  },
  ErrorMessage_Birthdate_MaxDate: {
    id: "ErrorMessage_Birthdate",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data superior à atual",
  },
  ErrorMessage_Birthdate_MinDate: {
    id: "ErrorMessage_Birthdate_MinDate",
    defaultMessage: "A data deve ser superior a {minDate}",
    description: "Mensagem de erro quando um campo de data de nascimento tem uma data inferior à data mínima",
  },
  ErrorMessage_MinRegisterDate: {
    id: "ErrorMessage_MinRegisterDate",
    defaultMessage: "A idade mínima para registo é de 17 anos.",
    description: "Mensagem de erro quando a data de nascimento para registo não perfaz 17 anos.",
  },
  ErrorMessage_ID_Card_InvalidFormat: {
    id: "ErrorMessage_ID_Card_InvalidFormat",
    defaultMessage: "O número de BI tem tamanho máximo de 14 caracteres",
    description: "Mensagem de erro quando um campo do formulário tem um formato inválido",
  },
});

export const labels = defineMessages({
  Label_Recruit_Submit: {
    id: "Label_Recruit_Submit",
    defaultMessage: "Recrutar",
    description: "Label de botões Recrutar",
  },
  Label_Reject_Submit: {
    id: "Label_Reject_Submit",
    defaultMessage: "Rejeitar",
    description: "Label de botões Rejeitar",
  },
  Label_Approve_Submit: {
    id: "Label_Approve_Submit",
    defaultMessage: "Aprovar",
    description: "Label de botões Aprovar",
  },
  Label_Admin_Subprogram_Create_CancelButton: {
    id: "Label_Admin_Subprogram_Create_CancelButton",
    defaultMessage: "Cancelar",
    description: "Label de botões Cancelar",
  },
  Label_Admin_Subprogram_Create_SubmitButton: {
    id: "Label_Admin_Subprogram_Create_SubmitButton",
    defaultMessage: "Guardar",
    description: "Label de botões Guardar",
  },
  Label_Admin_Subprogram_ProgramField: {
    id: "Label_Admin_Subprogram_ProgramField",
    defaultMessage: "Programa",
    description: "label do campo",
  },
  Label_Admin_Subprogram_SubprogramField: {
    id: "Label_Admin_Subprogram_SubprogramField",
    defaultMessage: "Subprograma",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateField: {
    id: "Label_Admin_Subprogram_ValidityDateField",
    defaultMessage: "Data de Vigência de",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateFieldTo: {
    id: "Label_Admin_Subprogram_ValidityDateFieldTo",
    defaultMessage: "Até",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateFieldStart: {
    id: "Label_Admin_Subprogram_ValidityDateFieldStart",
    defaultMessage: "Data de Vigência (Início)",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ValidityDateFieldEnd: {
    id: "Label_Admin_Subprogram_ValidityDateFieldEnd",
    defaultMessage: "Data de Vigência (Fim)",
    description: "label do campo",
  },
  Label_Admin_Subprogram_DescriptionField: {
    id: "Label_Admin_Subprogram_DescriptionField",
    defaultMessage: "Descrição",
    description: "label do campo",
  },
  Label_Admin_Subprogram_ObjectivesField: {
    id: "Label_Admin_Subprogram_ObjectivesField",
    defaultMessage: "Objectivos",
    description: "label do campo",
  },
  Label_Admin_Subprogram_NumVacanciesField: {
    id: "Label_Admin_Subprogram_NumVacanciesField",
    defaultMessage: "Nº de Benefícios a atribuir",
    description: "label do campo",
  },
  Label_Admin_Subprogram_DepartmentField: {
    id: "Label_Admin_Subprogram_DepartmentField",
    defaultMessage: "Pelouro Responsável",
    description: "label do campo",
  },
  Label_Admin_Subprogram_BenefitsTypeField: {
    id: "Label_Admin_Subprogram_BenefitsTypeField",
    defaultMessage: "Tipo de Benefício",
    description: "label do campo",
  },

  Label_Admin_ApplicationsManagementFilter_NumApplicationField: {
    id: "Label_Admin_ApplicationsManagementFilter_NumApplicationField",
    defaultMessage: "Nº Candidatura",
    description: "Label do campo de pesquisa por Nº Candidatura",
  },
  Label_Admin_ApplicationsManagementFilter_NumBIField: {
    id: "Label_Admin_ApplicationsManagementFilter_NumBIField",
    defaultMessage: "Nº BI",
    description: "Label do campo de pesquisa por Nº BI",
  },
  Label_Admin_ApplicationsManagementFilter_UserCodeField: {
    id: "Label_Admin_ApplicationsManagementFilter_UserCodeField",
    defaultMessage: "Código do utilizador",
    description: "Label do campo de pesquisa por Código do utilizador",
  },
  Label_Admin_ApplicationsManagementFilter_UserNameField: {
    id: "Label_Admin_ApplicationsManagementFilter_UserNameField",
    defaultMessage: "Nome",
    description: "Label do campo de pesquisa por Nome",
  },
  Label_Admin_ApplicationsManagementFilter_StatusField: {
    id: "Label_Admin_ApplicationsManagementFilter_StatusField",
    defaultMessage: "Estado",
    description: "Label do campo de pesquisa por Estado",
  },
  Label_Admin_ApplicationsManagementFilter_ProgramField: {
    id: "Label_Admin_ApplicationsManagementFilter_ProgramField",
    defaultMessage: "Programa",
    description: "Label do campo de pesquisa por Programa",
  },
  Label_Admin_ApplicationsManagementFilter_SubprogramField: {
    id: "Label_Admin_ApplicationsManagementFilter_SubprogramField",
    defaultMessage: "Subrograma",
    description: "Label do campo de pesquisa por Subrograma",
  },
  Label_Admin_ApplicationsManagementFilter_SubmissionDateFrom: {
    id: "Label_Admin_ApplicationsManagementFilter_SubmissionDateFrom",
    defaultMessage: "Data de submissão (desde)",
    description: "Label do campo de pesquisa por Data de submissão (desde)",
  },
  Label_Admin_ApplicationsManagementFilter_SubmissionDateTo: {
    id: "Label_Admin_ApplicationsManagementFilter_SubmissionDateTo",
    defaultMessage: "Data de submissão (até)",
    description: "Label do campo de pesquisa por Data de submissão (até)",
  },

  Label_Admin_ApplicationDetail_ApplicationStateField: {
    id: "Label_Admin_ApplicationDetail_ApplicationStateField",
    defaultMessage: "Estado da candidatura",
    description: "label do campo",
  },
  Label_Admin_ApplicationDetail_SubmitButton: {
    id: "Label_Admin_ApplicationDetail_SubmitButton",
    defaultMessage: "Submeter",
    description: "Label de botões submeter",
  },

  Label_Admin_GeneralDataSection_NameField: {
    id: "Label_Admin_GeneralDataSection_NameField",
    defaultMessage: "Nome",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_NumBIField: {
    id: "Label_Admin_GeneralDataSection_NumBIField",
    defaultMessage: "Nº BI",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_GenderField: {
    id: "Label_Admin_GeneralDataSection_GenderField",
    defaultMessage: "Género",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_MaritalStatusField: {
    id: "Label_Admin_GeneralDataSection_MaritalStatusField",
    defaultMessage: "Estado Civil",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_OtherApplicationsField: {
    id: "Label_Admin_GeneralDataSection_OtherApplicationsField",
    defaultMessage: "Outras candidaturas",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_SpecialNeedsField: {
    id: "Label_Admin_GeneralDataSection_SpecialNeedsField",
    defaultMessage: "Necessidades Especiais",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_SNPercentageField: {
    id: "Label_Admin_GeneralDataSection_SNPercentageField",
    defaultMessage: "Percentagem",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_AgeField: {
    id: "Label_Admin_GeneralDataSection_AgeField",
    defaultMessage: "Idade",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_BirthdayField: {
    id: "Label_Admin_GeneralDataSection_BirthdayField",
    defaultMessage: "Data Nascimento",
    description: "Label do campo de Dados Gerais do candidato",
  },
  Label_Admin_GeneralDataSection_MobilePhoneField: {
    id: "Label_Admin_GeneralDataSection_MobilePhoneField",
    defaultMessage: "Telemóvel",
    description: "Label do campo de Dados Gerais do candidato",
  },

  Label_Admin_ProgramSubprogramDataSection_WhichProvinceField: {
    id: "Label_Admin_ProgramSubprogramDataSection_WhichProvinceField",
    defaultMessage: "Onde pretende candidatar-se?",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ProgramsField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ProgramsField",
    defaultMessage: "Programas",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_SubprogramsField: {
    id: "Label_Admin_ProgramSubprogramDataSection_SubprogramsField",
    defaultMessage: "Subprogramas",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_BenefitTypeField: {
    id: "Label_Admin_ProgramSubprogramDataSection_BenefitTypeField",
    defaultMessage: "Tipo de benefício",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_MicrocreditAmountField: {
    id: "Label_Admin_ProgramSubprogramDataSection_MicrocreditAmountField",
    defaultMessage: "Valor microcrédito pretendido",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ConcededMicrocreditAmountField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ConcededMicrocreditAmountField",
    defaultMessage: "Valor microcrédito a conceder",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsNumberField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsNumberField",
    defaultMessage: "Nº de Kits pretendidos",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ConcededProfessionalKitsNumberField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ConcededProfessionalKitsNumberField",
    defaultMessage: "Nº de Kits a conceder",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsValueField: {
    id: "Label_Admin_ProgramSubprogramDataSection_ProfessionalKitsValueField",
    defaultMessage: "Valor",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_IntendedCourseNameField: {
    id: "Label_Admin_ProgramSubprogramDataSection_IntendedCourseNameField",
    defaultMessage: "Curso pretendido",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_IntendedWalletNameField: {
    id: "Label_Admin_ProgramSubprogramDataSection_IntendedWalletNameField",
    defaultMessage: "Carteira pretendida",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_TrainingCenterField: {
    id: "Label_Admin_ProgramSubprogramDataSection_TrainingCenterField",
    defaultMessage: "Centro de formação pretendido",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_InternshipAreaField: {
    id: "Label_Admin_ProgramSubprogramDataSection_InternshipAreaField",
    defaultMessage: "Área onde pretende fazer o estágio",
    description: "Label do campo de Dados do Programa e Subprograma",
  },
  Label_Admin_ProgramSubprogramDataSection_Bank: {
    id: "Label_Admin_ProgramSubprogramDataSection_Bank",
    defaultMessage: "Qual o banco para o crédito?",
    description: "Label do campo de seleção de banco nos detalhde de uma candidatura a um subprograma de microcrédito",
  },
  Label_Admin_ProgramSubprogramDataSection_Bank_Placeholder: {
    id: "Label_Admin_ProgramSubprogramDataSection_Bank_Placeholder",
    defaultMessage: "Seleccione o banco",
    description: "Label do campo de seleção de banco nos detalhde de uma candidatura a um subprograma de microcrédito",
  },

  Label_Admin_SchedulingManagementFilter_SchedulingDateFrom: {
    id: "Label_Admin_SchedulingManagementFilter_SchedulingDateFrom",
    defaultMessage: "Data de agendamento (desde)",
    description: "Label do campo de pesquisa por Data de agendamento (desde)",
  },
  Label_Admin_SchedulingManagementFilter_SchedulingDateTo: {
    id: "Label_Admin_SchedulingManagementFilter_SchedulingDateTo",
    defaultMessage: "Data de agendamento (até)",
    description: "Label do campo de pesquisa por Data de agendamento (até)",
  },
  Label_Admin_SchedulingTab_MotiveField: {
    id: "Label_Admin_SchedulingTab_MotiveField",
    defaultMessage: "Motivo",
    description: "Label do campo",
  },
  Label_Admin_SchedulingTab_SchedulingDateField: {
    id: "Label_Admin_SchedulingTab_SchedulingDateField",
    defaultMessage: "Data e Hora de Agendamento",
    description: "Label do campo",
  },
  Label_Admin_SchedulingTab_SchedulingHourField: {
    id: "Label_Admin_SchedulingTab_SchedulingHourField",
    defaultMessage: "Horas",
    description: "Label do campo",
  },
  Label_Admin_SchedulingTab_ObservationsField: {
    id: "Label_Admin_SchedulingTab_ObservationsField",
    defaultMessage: "Observações",
    description: "Label do campo",
  },

  Label_Admin_NotificationsTab_CandidateField: {
    id: "Label_Admin_NotificationsTab_CandidateField",
    defaultMessage: "Candidato",
    description: "Label do campo de Candidato no tab de notificações",
  },
  Label_Admin_NotificationsTab_RecipientField: {
    id: "Label_Admin_NotificationsTab_RecipientField",
    defaultMessage: "Destinatário",
    description: "Label do campo",
  },
  Label_Admin_NotificationsTab_BackofficeUserField: {
    id: "Label_Admin_NotificationsTab_BackofficeUserField",
    defaultMessage: "Utilizador de backoffice",
    description: "Label do campo",
  },
  Label_Admin_NotificationsTab_BackofficeGroupField: {
    id: "Label_Admin_NotificationsTab_BackofficeGroupField",
    defaultMessage: "Grupo de backoffice",
    description: "Label do campo de Grupo de backoffice no tab de notificações",
  },
  Label_Admin_NotificationsTab_MotiveField: {
    id: "Label_Admin_NotificationsTab_MotiveField",
    defaultMessage: "Motivo",
    description: "Label do campo de Motivo no tab de notificações",
  },
  Label_Admin_NotificationsTab_SubjectField: {
    id: "Label_Admin_NotificationsTab_SubjectField",
    defaultMessage: "Assunto",
    description: "Label do campo",
  },
  Label_Admin_NotificationsTab_ObservationsField: {
    id: "Label_Admin_NotificationsTab_ObservationsField",
    defaultMessage: "Mensagem",
    description: "Label do campo",
  },
  Label_Generic_Submit_Button: {
    id: "Label_Generic_Submit_Button",
    defaultMessage: "Submeter",
    description: "Label geral de botões submeter",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_CategoryField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_CategoryField",
    defaultMessage: "Especialidade",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_QuantityField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_QuantityField",
    defaultMessage: "Quantidade de perda",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_BatchNumberField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_BatchNumberField",
    defaultMessage: "Referência do Lote",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_ValueField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_ValueField",
    defaultMessage: "Valor Total",
    description: "Label do campo",
  },
  Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_MotiveField: {
    id: "Label_Component_ModalFormsContent_Stocks_RegisterLossBreak_MotiveField",
    defaultMessage: "Motivo",
    description: "Label do campo",
  },
  Label_Generic_Active: {
    id: "Label_Generic_Active",
    defaultMessage: "Activo",
    description: "Label 'Activo' que representa o estado do professional kit",
  },
  Label_Generic_Inactive: {
    id: "Label_Generic_Inactive",
    defaultMessage: "Inactivo",
    description: "Label 'Inactivo' que representa o estado do professional kit",
  },
  Label_Generic_Upload_Document: {
    id: "Label_Generic_Upload_Document",
    defaultMessage: "Carregar documento",
    description: "Label do campo",
  },
  Label_Admin_ScheduleDetails_ReportField: {
    id: "Label_Admin_ScheduleDetails_ReportField",
    defaultMessage: "Relatório",
    description: "Label da caixa de texto para o relatório do agendamento",
  },
  Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForInternshipOpportunities: {
    id: "Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForInternshipOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a estágios",
    description:
      "Label do campo 'Disponível para Oportunidades de Estágio' do ecrã de edição de informação pessoal do utilizador",
  },
  Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForEmploymentOpportunities: {
    id: "Label_Admin_EditIndividualUserPersonalData_Field_isAvailableForEmploymentOpportunities",
    defaultMessage: "Quero aparecer nas listas de candidatos a emprego",
    description:
      "Label do campo 'Disponível para Oportunidades de Emprego' do ecrã de edição de informação pessoal do utilizador",
  },
  Label_Admin_UserManagement_CreateColectiveUser_Button: {
    id: "Label_Admin_UserManagement_CreateColectiveUser_Button",
    defaultMessage: "Criar utilizador colectivo",
    description: "Label do botão que redireciona o utilizador para o ecrã de criação de utilizadores coletivos",
  },
  Label_Admin_UserManagement_CreateIndividualUser_Button: {
    id: "Label_Admin_UserManagement_CreateIndividualUser_Button",
    defaultMessage: "Criar utilizador individual",
    description: "Label do botão que redireciona o utilizador para o ecrã de criação de utilizadores individuais",
  },

  //Redirect PAPE to INEFOP
  Label_Redirect_To_INEDOP_Description_1: {
    id: "Label_Redirect_To_INEDOP_Description_1",
    defaultMessage: "Página fora de serviço.",
    description: "Label",
  },
  Label_Redirect_To_INEDOP_Description_2: {
    id: "Label_Redirect_To_INEDOP_Description_2",
    defaultMessage: "Será redirecionado para o novo portal do INEFOP em:",
    description: "Label",
  },
  Label_Redirect_To_INEDOP_Description_3: {
    id: "Label_Redirect_To_INEDOP_Description_3",
    defaultMessage:
      "No novo portal poderá entrar com as mesmas credenciais do SIGPAPE e terá acesso às mesmas e a novas funcionalidades!",
    description: "Label",
  },
});

export const titles = defineMessages({
  Title_AdminNavBar_NavBarMenu: {
    id: "Title_AdminNavBar_NavBarMenu",
    defaultMessage: "Menu",
    description: "Titulo do botão do menu em lista da NavBar",
  },
  Title_AdminNavBar_NavBarListMenu: {
    id: "Title_AdminNavBar_NavBarListMenu",
    defaultMessage: "Menu de Navegação",
    description: "Titulo do menu em lista da NavBar",
  },
  Title_AdminNavBar_GestaoCandidaturasMenu: {
    id: "Title_AdminNavBar_GestaoCandidaturasMenu",
    defaultMessage: "Gestão de Candidaturas",
    description: "Titulo do menu Gestão de Candidaturas",
  },
  Title_AdminNavBar_ListaDeCandidaturasMenuItem: {
    id: "Title_AdminNavBar_ListaDeCandidaturasMenuItem",
    defaultMessage: "Lista de Candidaturas",
    description: "Titulo de opção de item do menu Gestão de Candidaturas",
  },
  Title_AdminNavBar_AgendamentosMenuItem: {
    id: "Title_AdminNavBar_AgendamentosMenuItem",
    defaultMessage: "Agendamentos",
    description: "Titulo de opção de item do menu Gestão de Candidaturas",
  },
  Title_AdminNavBar_ProfessionalKitsMenu: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu",
    defaultMessage: "Kits Profissionais",
    description: "Titulo do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_KitsListMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_KitsListMenuItem",
    defaultMessage: "Lista Kits",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_CharacterizeKitMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_CharacterizeKitMenuItem",
    defaultMessage: "Caracterizar Kit",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_RegisterLossMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_RegisterLossMenuItem",
    defaultMessage: "Registar Perda",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_RegisterInWarehouseMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_RegisterInWarehouseMenuItem",
    defaultMessage: "Registo em Armazém",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_StocksMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_StocksMenuItem",
    defaultMessage: "Stocks",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_ProfessionalKitsMenu_KitDeliveryMenuItem: {
    id: "Title_AdminNavBar_ProfessionalKitsMenu_KitDeliveryMenuItem",
    defaultMessage: "Entrega de kit",
    description: "Titulo de opção de item do menu Kits Profissionais",
  },
  Title_AdminNavBar_SubprogramasMenu: {
    id: "Title_AdminNavBar_SubprogramasMenu",
    defaultMessage: "Subprogramas",
    description: "Titulo do menu Subprogramas",
  },
  Title_AdminNavBar_ListaSubprogramasMenuItem: {
    id: "Title_AdminNavBar_ListaSubprogramasMenuItem",
    defaultMessage: "Lista Subprogramas",
    description: "Titulo de opção de item de menu Subprogramas",
  },
  Title_AdminNavBar_CharacterizeSubprogramMenuItem: {
    id: "Title_AdminNavBar_CharacterizeSubprogramMenuItem",
    defaultMessage: "Caracterizar subprograma",
    description: "Titulo de opção de item de menu Subprogramas",
  },

  Title_AdminNavBar_ListaInformationDisseminationMenuItem: {
    id: "Title_AdminNavBar_ListaInformationDisseminationMenuItem",
    defaultMessage: "Lista Informação",
    description: "Titulo de opção de item de menu Criação Informação",
  },
  Title_AdminNavBar_CreateInformationDisseminationMenuItem: {
    id: "Title_AdminNavBar_CreateInformationDisseminationMenuItem",
    defaultMessage: "Criar Informação",
    description: "Titulo de opção de item de menu Criação Informação",
  },

  Title_AdminNavBar_MentorsMenuItem: {
    id: "Title_AdminNavBar_MentorsMenuItem",
    defaultMessage: "Mentores",
    description: "Titulo de opção de item de menu Criação Informação",
  },

  Title_AdminNavBar_MenteesMenuItem: {
    id: "Title_AdminNavBar_MenteesMenuItem",
    defaultMessage: "Mentorados",
    description: "Titulo de opção de item de menu Criação Informação",
  },

  Title_AdminNavBar_ListCandidatesMenuItem: {
    id: "Title_AdminNavBar_ListCandidatesMenuItem",
    defaultMessage: "Lista de Candidatos",
    description: "Titulo de opção de item de menu Lista Candidatos",
  },
  Title_AdminNavBar_ListOpportunitiesMenuItem: {
    id: "Title_AdminNavBar_ListOpportunitiesMenuItem",
    defaultMessage: "Lista de Ofertas",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },
  Title_AdminNavBar_ListOpportunitiesApplicationsMenuItem: {
    id: "Title_AdminNavBar_ListOpportunitiesApplicationsMenuItem",
    defaultMessage: "Lista de Candidaturas",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },
  Title_AdminNavBar_ListOpportunitiesApplicationsFollowUpMenuItem: {
    id: "Title_AdminNavBar_ListOpportunitiesApplicationsFollowUpMenuItem",
    defaultMessage: "Lista de Estágios",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },
  Title_AdminNavBar_OpportunityCreateMenuItem: {
    id: "Title_AdminNavBar_OpportunityCreateMenuItem",
    defaultMessage: "Criar Oferta",
    description: "Titulo de opção de item de menu Emprego e Estágio",
  },

  Title_AdminNavBar_Bancos: {
    id: "Title_AdminNavBar_Bancos",
    defaultMessage: "Bancos",
    description: "Titulo de opção Bancos",
  },
  Title_AdminNavBar_InformationDissemination: {
    id: "Title_AdminNavBar_InformationDissemination",
    defaultMessage: "Informação",
    description: "Titulo de opção de Informação",
  },
  Title_AdminNavBar_Opportunity: {
    id: "Title_AdminNavBar_Opportunity",
    defaultMessage: "Emprego e Estágio",
    description: "Titulo de opção Emprego e Estágio",
  },
  Title_AdminNavBar_Mentoring: {
    id: "Title_AdminNavBar_Mentoring",
    defaultMessage: "Mentoria",
    description: "Titulo de opção de Mentoria",
  },
  Title_AdminNavBar_ManageUsersMenu: {
    id: "Title_AdminNavBar_ManageUsersMenu",
    defaultMessage: "Gestão de Utilizadores",
    description: "Titulo do menu de Gestão de Utilizadores",
  },
  Title_AdminNavBar_ManageUsersMenuItemBackOffice: {
    id: "Title_AdminNavBar_ManageUsersMenuItemBackOffice",
    defaultMessage: "Utilizadores Backoffice",
    description: "Titulo de opção de item de menu de Gestão Utilizadores",
  },
  Title_AdminNavBar_ManageUsersMenuItemFrontOffice: {
    id: "Title_AdminNavBar_ManageUsersMenuItemFrontOffice",
    defaultMessage: "Utilizadores Frontoffice",
    description: "Titulo de opção de item de menu de Gestão Utilizadores",
  },
  Title_Admin_Subprogram_GeneralDataSection: {
    id: "Title_Admin_Subprogram_GeneralDataSection",
    defaultMessage: "Dados Gerais",
    description: "Titulo da secção de dados gerais",
  },
  Title_Admin_Subprogram_BenefitsSection: {
    id: "Title_Admin_Subprogram_BenefitsSection",
    defaultMessage: "Benefícios",
    description: "Titulo da secção de benefícios",
  },
  Title_Admin_Subprogram_DocumentsSection: {
    id: "Title_Admin_Subprogram_DocumentsSection",
    defaultMessage: "Documentos",
    description: "Titulo da secção de documentos",
  },

  Title_Admin_ApplicationsManagement: {
    id: "Title_Admin_ApplicationsManagement",
    defaultMessage: "Gestão de Candidaturas",
    description: "Titulo da página de Gestão de Candidaturas",
  },
  Title_Admin_ApplicationsManagement_ResultSection: {
    id: "Title_Admin_ApplicationsManagement_ResultSection",
    defaultMessage: "Candidaturas",
    description: "Titulo da tabela de resultados na página de Gestão de Candidaturas",
  },

  Title_Admin_ApplicationDetail_GeneralDataSectionButton: {
    id: "Title_Admin_ApplicationDetail_GeneralDataSectionButton",
    defaultMessage: "Dados Gerais",
    description: "Titulo do botão para a secção de Dados Gerais do candidato",
  },
  Title_Admin_ApplicationDetail_GeneralDataSection: {
    id: "Title_Admin_ApplicationDetail_GeneralDataSection",
    defaultMessage: "Dados Gerais do candidato",
    description: "Titulo da secção de Dados Gerais do candidato",
  },
  Title_Admin_ApplicationDetail_ProgramSubprogramDataSection: {
    id: "Title_Admin_ApplicationDetail_ProgramSubprogramDataSection",
    defaultMessage: "Dados do Programa e Subprograma",
    description: "Titulo da secção de Dados do Programa e Subprograma",
  },
  Title_Admin_ApplicationDetail_ComplementaryDataSection: {
    id: "Title_Admin_ApplicationDetail_ComplementaryDataSection",
    defaultMessage: "Dados Complementares",
    description: "Titulo da secção de Dados Complementares",
  },
  Title_Admin_ApplicationDetail_DocumentsSection: {
    id: "Title_Admin_ApplicationDetail_DocumentsSection",
    defaultMessage: "Documentos",
    description: "Titulo da secção de Documentos",
  },
  Title_Admin_ApplicationDetail_SchedulingSection: {
    id: "Title_Admin_ApplicationDetail_SchedulingSection",
    defaultMessage: "Agendamento",
    description: "Titulo do botão e da secção de Agendamento",
  },
  Title_Admin_ApplicationDetail_ReportsSection: {
    id: "Title_Admin_ApplicationDetail_ReportsSection",
    defaultMessage: "Relatórios",
    description: "Titulo da secção de Agendamento",
  },
  Title_Admin_ApplicationDetail_NotificationsSection: {
    id: "Title_Admin_ApplicationDetail_NotificationsSection",
    defaultMessage: "Notificações",
    description: "Titulo do botão e da secção de Notificações",
  },
  Title_Admin_ApplicationDetail_HistorySectionButton: {
    id: "Title_Admin_ApplicationDetail_HistorySectionButton",
    defaultMessage: "Histórico",
    description: "Titulo do botão para a secção de Histórico",
  },
  Title_Admin_ApplicationDetail_HistorySection: {
    id: "Title_Admin_ApplicationDetail_HistorySection",
    defaultMessage: "Linha do Tempo",
    description: "Titulo da secção de Histórico",
  },
  Title_Admin_ApplicationDetail_HistoryNotesSection: {
    id: "Title_Admin_ApplicationDetail_HistoryNotesSection",
    defaultMessage: "Notas / Comentários",
    description: "Titulo da secção de Histórico",
  },

  Title_Admin_SchedulesManagement: {
    id: "Title_Admin_SchedulesManagement",
    defaultMessage: "Agendamentos",
    description: "Titulo da página de Agendamentos",
  },
  Title_Admin_SchedulesManagement_ResultSection: {
    id: "Title_Admin_SchedulesManagement_ResultSection",
    defaultMessage: "Agendamentos em curso",
    description: "Titulo da tabela de resultados na página de Agendamentos",
  },
  Title_Admin_SchedulesManagement_ScheduleDetails_RegistrationData: {
    id: "Title_Admin_SchedulesManagement_ScheduleDetails_RegistrationData",
    defaultMessage: "Dados do Registo",
    description: "Titulo da secção da página de detalhes de agendamentos",
  },

  Title_Admin_ProfessionalKitsManagement: {
    id: "Title_Admin_ProfessionalKitsManagement",
    defaultMessage: "Gestão de Kits",
    description: "Titulo da página de Gestão de Kits",
  },
  Title_Admin_ProfessionalKits_ResultSection: {
    id: "Title_Admin_ProfessionalKits_ResultSection",
    defaultMessage: "Kits Profissionais",
    description: "Titulo da tabela de resultados na página de Kits Profissionais",
  },

  Title_Admin_ProfessionalKits_StocksManagement: {
    id: "Title_Admin_ProfessionalKits_StocksManagement",
    defaultMessage: "Gestão de Stocks",
    description: "Titulo da página de Gestão de Stocks",
  },
  Title_Admin_ProfessionalKits_StocksManagement_ResultSection: {
    id: "Title_Admin_ProfessionalKits_StocksManagement_ResultSection",
    defaultMessage: "Stocks",
    description: "Titulo da tabela de resultados na página de Gestão de Stocks",
  },

  Title_Component_ModalFormsContent_Stocks_RegisterLossBreak: {
    id: "Title_Component_ModalFormsContent_Stocks_RegisterLossBreak",
    defaultMessage: "Registo de Perda",
    description: "Titulo da modal de registo de quebra/perda de kits",
  },
  Title_Opportunity_Employment_Data_Card: {
    id: "Title_Opportunity_Employment_Data_Card",
    defaultMessage: "Dados da oferta de emprego",
    description: "Título do cartão de definição dos dados da oferta de emprego",
  },
  Title_Opportunity_Internship_Data_Card: {
    id: "Title_Opportunity_Internship_Data_Card",
    defaultMessage: "Dados da oferta de estágio profissional",
    description: "Título do cartão de definição dos dados da oferta de estágio",
  },
  Title_Opportunity_Employment_RejectMotive_Card: {
    id: "Title_Opportunity_Employment_RejectMotive_Card",
    defaultMessage: "Motivo de rejeição",
    description: "Título do cartão com o motivo da rejeição da oferta",
  },
  Title_Opportunity_Employment_CloseMotive_Card: {
    id: "Title_Opportunity_Employment_CloseMotive_Card",
    defaultMessage: "Motivo de fecho",
    description: "Título do cartão com o motivo do fecho da oferta",
  },
});

export const messages = defineMessages({
  Message_Generic_MandatoryField: {
    id: "Message_Generic_MandatoryField",
    defaultMessage: "O campo é obrigatório",
    description: "Mensagem genérica para qualquer campo que seja obrigatório",
  },
  Message_Generic_ValueGreaterThanZeroField: {
    id: "Message_Generic_ValueGreaterThanZeroField",
    defaultMessage: "O valor tem de ser superior a zero",
    description: "Mensagem genérica para qualquer campo que o valor tenha de ser superior a zero",
  },
  Message_Generic_DateGreaterThanTodayField: {
    id: "Message_Generic_DateGreaterThanTodayField",
    defaultMessage: "A data deve ser igual ou superior à data actual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser igual ou superior à data actual",
  },
  Message_Generic_DateLessThanTodayField: {
    id: "Message_Generic_DateLessThanTodayField",
    defaultMessage: "A data deve ser inferior à data atual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser inferior à data actual",
  },
  Message_Generic_DateLessThanOrEqualToTodayField: {
    id: "Message_Generic_DateLessThanOrEqualToTodayField",
    defaultMessage: "A data deve ser igual ou inferior à data actual",
    description: "Mensagem genérica para qualquer campo em que a data tenha de ser igual ou inferior à data actual",
  },
  Message_Generic_DateToGreaterThanDateFromField: {
    id: "Message_Generic_DateToGreaterThanDateFromField",
    defaultMessage: "A data deve ser superior à data de início",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de início",
  },
  Message_Generic_DateToGreaterThanOrEqualToDateFromField: {
    id: "Message_Generic_DateToGreaterThanOrEqualToDateFromField",
    defaultMessage: "A data deve ser igual ou superior à data de início",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser igual ou superior à data de início",
  },
  Message_Generic_DateFromLessThanDateToField: {
    id: "Message_Generic_DateFromLessThanDateToField",
    defaultMessage: "A data deve ser inferior à data de fim",
    description: "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser superior à data de fim",
  },
  Message_Generic_DateFromLessThanOrEqualToDateToField: {
    id: "Message_Generic_DateFromLessThanOrEqualToDateToField",
    defaultMessage: "A data deve ser igual ou inferior à data de fim",
    description:
      "Mensagem genérica para qualquer campo de intervalo em que a data tenha de ser igual ou superior à data de fim",
  },
  Message_Generic_InvalidField: {
    id: "Message_Generic_InvalidField",
    defaultMessage: "Formato incorrecto",
    description: "Mensagem genérica para qualquer campo que não cumpra o formato indicado",
  },
  Message_Admin_HistoryTab_AdicionalCommentsField: {
    id: "Message_Admin_HistoryTab_AdicionalCommentsField",
    defaultMessage: "Deixe aqui os seus comentários adicionais",
    description: "Mensagem default do campo",
  },
  Message_Auth_Password_Rules_1: {
    id: "Message_Auth_Password_Rules_1",
    defaultMessage: "A palavra-passe tem de conter:",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_2: {
    id: "Message_Auth_Password_Rules_2",
    defaultMessage: "Pelo menos 10 caracteres",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_3: {
    id: "Message_Auth_Password_Rules_3",
    defaultMessage: "Pelo menos 1 letra maiúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_4: {
    id: "Message_Auth_Password_Rules_4",
    defaultMessage: "Pelo menos 1 letra minúscula",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_5: {
    id: "Message_Auth_Password_Rules_5",
    defaultMessage: "Pelo menos 1 número",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
  Message_Auth_Password_Rules_6: {
    id: "Message_Auth_Password_Rules_6",
    defaultMessage: "Pelo menos 1 caracter especial",
    description: "Label do botão de navegação do formulário que permite navegar para a página anterior",
  },
});
export default errorMessages;
