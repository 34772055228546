import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import jwt_decode from "jwt-decode";
import { refresh_token } from "crud/auth.crud";
import {AUTH} from "utils/actionsConsts";

const white_list = [
  "login",
  "refresh-token",
  "recover-password",
  "reset-password",
];
class UndecoratedSetupAxios extends React.Component {
  static propTypes = {
    //auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    axios: PropTypes.func,
    children: PropTypes.node,
    requestInterceptorHandler: PropTypes.func,
    requestInterceptorErrorHandler: PropTypes.func,
    responseInterceptorSuccessHandler: PropTypes.func,
    responseInterceptorErrorHandler: PropTypes.func,
  };

  static defaultProps = {
    axios,
    children: null,
    requestInterceptorHandler: (config) => config,
    requestInterceptorErrorHandler: (error) => Promise.reject(error),
    responseInterceptorSuccessHandler: (response) => response,
    responseInterceptorErrorHandler: (error) => Promise.reject(error),
  };

  static displayName = "SetupAxios";

  requestInterceptor = null;
  responseInterceptor = null;

  componentDidMount() {
    console.log("setup_axios");

    this.requestInterceptor = this.props.axios.interceptors.request.use(
      this.requestInterceptorSuccessHandler,
      this.requestInterceptorErrorHandler
    );
    this.responseInterceptor = this.props.axios.interceptors.response.use(
      this.responseInterceptorSuccessHandler,
      this.responseInterceptorErrorHandler
    );
  }

  componentWillUnmount() {
    this.props.axios.interceptors.request.eject(this.requestInterceptor);
    this.props.axios.interceptors.response.eject(this.responseInterceptor);
  }

  requestInterceptorSuccessHandler = async (config) => {
    var token = this.props.store.getState().authState?.token;

    if (this.check_white_list(config.url)) {
      return config;
    }
    // If not able to retrieve a token, send the user back to login
    if (typeof token === "undefined") {
      this.props.history.push("/auth/login");
      return config;
    }

    if (this.is_token_expired(token)) {
      console.log("token is expired");

      await refresh_token(this.props.store.getState().authState?.refreshToken)
        .then((res) => {
          console.log("refresh token response", res.data);

          if (res.data.refreshToken) {
            this.props.store.dispatch({
              type: "refresh-token",
              payload: {
                jwtToken: res.data.jwtToken,
                refreshToken: res.data.refreshToken,
              },
            });
          }
          else
          {
            this.props.store.dispatch({
              type: AUTH.SESSION_EXPIRED
            });
            this.props.history.push("/auth/login");
          }

          token = res.data.jwtToken;
        })
        .catch((error) => {
          console.log("error on refresh token response", error);
        });
    }

    // Process the user supplied requestInterceptorHandler
    const newConfig = this.props.requestInterceptorHandler(config);
    // Return the config with the token appended to the Authorization Header
    return {
      ...newConfig,
      headers: {
        ...get(newConfig, "headers", {}),
        Authorization: `Bearer ${token}`,
      },
    };
  };

  requestInterceptorErrorHandler = (error) =>
    this.props.requestInterceptorErrorHandler(error);

  responseInterceptorSuccessHandler = (response) =>
    this.props.responseInterceptorSuccessHandler(response);

  responseInterceptorErrorHandler = (error) => {
    if (get(error, "response.status") === 401) {
      console.log("setup axios", error);

      this.props.store.dispatch({
        type: "logout",
        payload: false,
      });
      this.props.history.push("/auth/login");
    }
    return this.props.responseInterceptorErrorHandler(error);
  };

  is_token_expired = (token) => {
    var current_time = new Date().getTime() / 1000;
    var jwt = jwt_decode(token);
    return current_time > jwt.exp;
  };

  check_white_list = (url) => {
    var flag = false;

    white_list.forEach((el) => (url.includes(el) ? (flag = true) : ""));

    return flag;
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(UndecoratedSetupAxios);
