import { INFORMATION_DISSEMINATION } from "utils/actionsConsts";
import moment from "moment";

const informationDisseminationReducer = (
  state = {
    createInformation_loading: false,
    searchInformation_loading: false,
    updateInformation_loading: false,
    get_information_details_loading: false,
    refresh_results: false,
    total_records: 0,
    total_pages: 0,
    result: [],
  },
  action
) => {
  switch (action.type) {
    case INFORMATION_DISSEMINATION.CREATE:
      return {
        ...state,
        result: action.payload,
        createInformation_loading: false,
      };
    case INFORMATION_DISSEMINATION.CREATE_INFORMATION_DISSEMINATION_LOADING:
      return { ...state, createInformation_loading: true };
    case INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION:
      return {
        ...state,
        result: action.payload,
        total_records: action.payload.total_records,
        total_pages: action.payload.total_pages,
      };
    case INFORMATION_DISSEMINATION.GET_FILTERED_INFORMATION_DISSEMINATION_LOADING:
      return { ...state, searchInformation_loading: action.payload };
    case INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS:
      return { ...state, informationDetailsData: action.payload };
    case INFORMATION_DISSEMINATION.GET_INFORMATION_DISSEMINATION_DETAILS_LOADING:
      return { ...state, get_information_details_loading: action.payload };
    case INFORMATION_DISSEMINATION.UPDATE_INFORMATION_DISSEMINATION_LOADING:
      return { ...state, updateInformation_loading: action.payload };
    case INFORMATION_DISSEMINATION.REFRESH_RESULTS:
      return { ...state, refresh_results: action.payload };
    case INFORMATION_DISSEMINATION.CLEAR_DETAIL:
      return { ...state, informationDetailsData: {} };
    case INFORMATION_DISSEMINATION.PUBLISH:
      if (action.payload !== null) {
        let indexOfInfoDisseminationItem = state.result?.data.findIndex((cd) => cd.alternateKey === action.payload.alternateKey);
        state.result.data[indexOfInfoDisseminationItem].publishedOn =
          action.payload.publishedOn !== null ? moment(action.payload.publishedOn).format("DD/MM/YYYY") : null;
        state.result.data[indexOfInfoDisseminationItem].published =
          action.payload.published !== null ? action.payload.published : false;
      }
      return { ...state };
    default:
      return state;
  }
};

export default informationDisseminationReducer;
