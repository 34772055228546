import axios from "axios";
import { INTERNAL_API_URL, EXTERNAL_API_URL } from "../index";

export const LOGIN_URL = "account/login";
export const REGISTER_URL = "account/register";
export const REFRESH_TOKEN_URL = "account/refresh-token";
export const LOGOUT_URL = "account/logout";
export const RECOVER_PASSWORD_URL = "User/recover-password";
export const GET_USER_PERMISSIONS = "account/get_user_permissions";
export const CHANGE_PASSWORD_URL = "User/change-password";
export const RESET_PASSWORD_URL = "User/reset-password";

export const ME_URL = "api/auth/get_user";

export function login(username, password) {
  return axios.post(INTERNAL_API_URL + LOGIN_URL, {
    username: username,
    password: password,
  });
}

export function changePassword(formData) {
  return axios.post(EXTERNAL_API_URL + CHANGE_PASSWORD_URL, formData);
}

export function register(email, username, password) {
  return axios.post(INTERNAL_API_URL + REGISTER_URL, {
    name: username,
    email: email,
    password: password,
  });
}

export function logout(username) {
  return axios.post(INTERNAL_API_URL + LOGOUT_URL, {
    email: username,
  });
}

export function refresh_token(refreshToken) {
  return axios.post(INTERNAL_API_URL + REFRESH_TOKEN_URL, {
    refreshToken: refreshToken,
  });
}

export function recover_password(email) {
  return axios.post(INTERNAL_API_URL + RECOVER_PASSWORD_URL, {
    username: email,
  });
}

export function reset_password(username, password, token) {
  return axios.post(INTERNAL_API_URL + RESET_PASSWORD_URL, {
    token: token,
    username: username,
    newPassword: password,
  });
}
