export const SUBPROGRAM = {
  CREATE: "createSubprogram",
  GET_FILTERED_SUBPROGRAMS: "getFilteredSubprograms",
  GET_FILTERED_SUBPROGRAMS_LOADING: "getFilteredSubprogramsLoading",
  GET_SUBPROGRAM_DETAILS: "getSubprogramDetails",
  GET_SUBPROGRAM_DETAILS_LOADING: "getSubprogramDetailsLoading",
  UPDATE: "updateSubprogram",
  UPDATE_STATE: "updateSubprogramState",
  REFRESH_RESULTS: "subprogramsRefreshResults",
  CLEAR_DETAIL: "clearSubprogram",
  CREATE_SUBPROGRAM_LOADING: "createSubprogram_loading",
  UPDATE_SUBPROGRAM_LOADING: "updateSubprogram_loading",
  UPDATE_SUBPROGRAM_LOADING: "updateSubprogramState_loading",
};

export const USERS = {
  GET_FRONTOFFICE_FILTERED_SEARCH: "getFilteredFrontOfficeUsers",
  GET_FRONTOFFICE_FILTERED_SEARCH_LOADING: "getFilteredFrontOfficeUsersLoading",
  GET_BACKOFFICE_FILTERED_SEARCH: "getFilteredBackOfficeUsers",
  GET_BACKOFFICE_FILTERED_SEARCH_LOADING: "getFilteredBackOfficeUsersLoading",
  GET_USER_INFO: "getUserInfo",
  GET_USER_INFO_LOADING: "getUserInfoLoading",
  GET_USER_INFO_ENT: "getUserInfoEnt",
  GET_USER_INFO_BO: "getUserInfoBo",
  GET_USER_INFO_BO_LOADING: "getUserInfoBo_Loading",
  UPDATE_INDIVIDUAL_USER: "frontOfficeIndividualUserUpdate",
  UPDATE_INDIVIDUAL_USER_LOADING: "frontOfficeIndividualUserUpdateLoading",
  UPDATE_ENTERPRISE_USER: "frontOfficeIndividualUserUpdate",
  UPDATE_ENTERPRISE_USER_LOADING: "frontOfficeIndividualUserUpdateLoading",
  UPDATE_BO_USER: "backOfficeUserUpdate",
  UPDATE_BO_USER_LOADING: "backOfficeUserUpdateLoading",
  UPDATE_Profile: "updateProfile",
  UPDATE_Profile_LOADING: "updateProfile_loading",
  GET_USER_DOCUMENT_DATA_DOWNLOAD: "getUserDocument",
  VALIDATE_COLECTIVE_USER: "validateColectiveUser",
  VALIDATE_COLECTIVE_USER_LOADING: "validateColectiveUser_loading",
  GET_FO_USER_COLECTIVE_BASIC_INFO: "getFoUserColectiveBasicInfo",
  REGISTER_FO_USER_LOADING: "registerFO_User_loading",
  GET_APPLICATION_VERSION: "getApplicationVersion",
  GET_APPLICATION_VERSION_LOADING: "application_version_loading",
};

export const REGISTER_BO_USER = {
  REGISTER: "backOfficeUserRegister",
  REGISTER_LOADING: "backOfficeUserRegister_loading",
};

export const REGISTER_FO_ENTERPRISE_USER = {
  REGISTER: "frontOfficeEnterpriseUserRegister",
};

export const REGISTER_FO_INDIVIDUAL_USER = {
  REGISTER: "frontOfficeIndividualUserRegister",
};

export const LOANS = {
  GET_FILTERED_LOANS: "getFilteredLoans",
  GET_LOAN_DETAILS: "getLoanDetails",
  GET_LOAN_DETAILS_LOADING: "getLoanDetailsLoading",
  GET_FILTERED_LOANS_LOADING: "getFilteredLoansLoading",
  REJECT_LOAN_LOADING: "rejectLoanLoading",
  UPDATE: "updateLoan",
  REFRESH_RESULTS: "refreshLoansResults",
};

export const DIALOG = {
  SHOW: "showGenericDialog",
  HIDE: "hideGenericDialog",
  SIMPLE_TYPE: "simpleType",
  ONE_BUTTON_TYPE: "oneButtonType",
  TWO_BUTTON_TYPE: "twoButtonType",
};

export const DIALOG_ICON = {
  QUESTION: "question",
};

export const DATASET_ACTIONS = {
  GET_DATASET_BY_NAME: "getDataSetByName",
  GET_DATASET_BY_INDEX: "getDataSetByIndex",
  GET_DATASET_BY_PROPERTY_ID: "getChildrenByPropertyId",
  GET_GEOGRAPHIC_DATA: "getGeographicData",
  GET_GEOGRAPHIC_DATA_BY_PROVINCE: "getGeographicDataByProvince",
  GET_NATIONALITIES: "getNationalities",
  GET_PROGRAMS: "getPrograms",
  GET_SUBPROGRAM_STATES: "getSubprogramStates",
  GET_SUBPROGRAM_BY_PROGRAM: "getSubprogramByProgramId",
  GET_DEPARTMENTS: "getDepartments",
  GET_CATEGORIES: "getCategories",
  GET_ACTIVE_CATEGORIES: "getActiveCategories",
  GET_NOT_ASSIGNED_ACTIVE_CATEGORIES: "getNotAssignedActiveCategories",
  GET_INACTIVE_CATEGORIES: "getInactiveCategories",
  GET_ALL_DATASET_TYPES: "getAllDatasetTypes",
  GET_WAREHOUSE_BY_PROVINCE: "getWarehousesByProvince",
  GET_LOSS_BREACK_MOTIVES: "getLossBreakMotives",
  GET_DOCUMENTS: "getDocuments",
  GET_CONDITIONS: "getConditions",
  GET_NEXT_APPLICATION_STATES: "getNextApplicationStates",
  GET_SUBPROGRAMS: "getSubprograms",
  GET_BO_USERS: "getBackOfficeUsers",
  GET_BO_ROLES: "getBackOfficeRoles",
  GET_SHAPE_PROGRAMS: "getShapePrograms",
  GET_SHAPE_SUBSITES: "getShapeSubsites",
  GET_LOAN_STATES: "getLoanStates",
  GET_LOAN_REJECT_MOTIVES: "getLoanRejectMotives",
  GET_APPLICATION_REJECT_MOTIVES: "getApplicationRejectMotives",
  CLEAR_DATASET_BY_NAME: "clearDataSetByName",
  GET_PREDEFINED_QUESTIONS: "getPredefinedQuestions",
  GET_MENTOR_STATES: "getMentorStates",
  GET_AVAILABLE_INTERNSHIP_OPPORTUNITIES_ACTIONS:
    "getAvailableInternshipOpportunities",
};

export const APPLICATIONS = {
  GET_FILTERED_APPLICATIONS: "getFilteredApplications",
  GET_FILTERED_APPLICATIONS_LOADING: "getFilteredApplicationsLoading",
  GET_APPLICATION_DETAILS_GENERAL_DATA: "getApplicationDetailsGeneralData",
  GET_APPLICATION_DETAILS_GENERAL_DATA_LOADING:
    "getApplicationDetailsGeneralDataLoading",
  GET_APPLICATION_UPDATE_LOADING: "getApplicationUpdateLoading",
  UPDATE_APPLICATION_DETAILS_GENERAL_DATA: "updateApplicationDetailGeneralData",
  GET_APPLICATION_DETAILS_HISTORY: "getApplicationDetailsHistory",
  APPLICATION_DETAILS_HISTORY_REFRESH_RESULTS:
    "applicationDetailsHistoryRefreshResults",
  CREATE_APPLICATION_DETAILS_HISTORY_ENTRY: "addApplicationDetailHistoryEntry",
  GET_SUBPROGRAM_COMPLEMENTARY_DATA: "getSubprogramComplementaryData",
  GET_APPLICATION_DOCUMENT_DATA_DOWNLOAD: "getApplicationDocumentData",
};

export const NOTIFICATION = {
  GET_NOTIFICATIONS: "getNotifications",
  GET_NOTIFICATIONS_LOADING: "getNotificationsLoading",
  GET_UNREAD_NOTIFICATIONS: "getUnreadNotifications",
  GET_UNREAD_NOTIFICATIONS_LOADING: "getUnreadNotificationsLoading",
  UPDATE_NOTIFICATION: "updateNotifications",
  CREATE: "createNotification",
  CREATE_LOADING: "createNotificationLoading",
  GET_TOTAL_UNREAD_NOTIFICATIONS: "getNumberOfUnreadNotifications",
};

export const SCHEDULING = {
  CREATE_LOADING: "createSchedulingLoading",
  SEARCH: "searchSchedulings",
  SEARCH_LOADING: "searchSchedulingsLoading",
  GET: "getScheduling",
  GET_LOADING: "getSchedulingLoading",
  CLEAR_DETAIL: "clearScheduling",
  UPDATE: "updateScheduling",
  UPDATE_LOADING: "updateSchedulingLoading",
  GET_REPORT_DOCUMENT_DATA_DOWNLOAD: "getReportDocument",
};

export const AUTH = {
  SESSION_EXPIRED: "session_expired",
  RESET_SESSION_EXPIRED: "reset_session_expired",
  RECOVER_PASSWORD: "recover_password",
  RECOVER_PASSWORD_LOADING: "recover_password_loading",
  RESET_RECOVER_STATE: "resetRecoverState",
  RESET_PASSWORD: "reset_password",
  RESET_PASSWORD_LOADING: "reset_password_loading",
  RESET_RESET_PASSWORD_STATE: "reset_reset_password_state",
};

export const PROFESSIONAL_KITS = {
  SEARCH_LOADING: "searchProfessionalKitsLoading",
  GET_DETAILS_LOADING: "getProfessionalKitDetailsLoading",
};

export const STOCKS = {
  SEARCH_LOADING: "searchStocksLoading",
  GET_STOCK_DETAILS_LOADING: "getStockDetailsLoading",
};

export const INFORMATION_DISSEMINATION = {
  CREATE: "createInformation",
  CREATE_INFORMATION_DISSEMINATION_LOADING: "createInformation_loading",
  GET_FILTERED_INFORMATION_DISSEMINATION: "getFilteredInformation",
  GET_FILTERED_INFORMATION_DISSEMINATION_LOADING:
    "getFilteredInformationLoading",
  GET_INFORMATION_DISSEMINATION_DETAILS: "getInformationDetails",
  GET_INFORMATION_DISSEMINATION_DETAILS_LOADING: "getInformationDetailsLoading",
  REFRESH_RESULTS: "informationRefreshResults",
  CLEAR_DETAIL: "clearInformation",
  // UPDATE: "updateInformation",
  UPDATE_INFORMATION_DISSEMINATION_LOADING: "updateInformation_loading",
  PUBLISH: "publish",
};

export const MENTORING = {
  GET_FILTERED_MENTORS: "getFilteredMentors",
  GET_FILTERED_MENTORS_LOADING: "getFilteredMentorsLoading",

  GET_FILTERED_MENTEES: "getFilteredMentees",
  GET_FILTERED_MENTEES_LOADING: "getFilteredMenteesLoading",

  MENTORS_REFRESH_RESULTS: "mentorsRefreshResults",
  MENTEES_REFRESH_RESULTS: "menteesRefreshResults",

  GET_MENTEE_INFO: "getMenteeInfo",
  GET_MENTEE_INFO_LOADING: "getMenteeInfoLoading",

  GET_MENTOR_BY_MENTEE_INFO: "getMentorMenteeInfo",
  GET_MENTOR_BY_MENTEE_INFO_LOADING: "getMentorMenteeInfoLoading",
  MENTOR_BY_MENTEE_REFRESH_RESULTS: "mentorMenteeRefreshResults",

  GET_MENTOR_INFO: "getMentorInfo",
  GET_MENTOR_INFO_LOADING: "getMentorInfoLoading",
  MENTOR_APPROVE_LOADING: "mentorApproveLoading",

  GET_MENTEES_BY_MENTOR: "getMenteesByMentor",
  GET_MENTEES_BY_MENTOR_LOADING: "getMenteesByMentorLoading",
  MENTEES_BY_MENTOR_REFRESH_RESULTS: "menteesByMentorRefreshResults",

  MENTORING_ASSIGN_LOADING: "mentoringAssignLoading",
  MENTORING_DISASSOCIATION_LOADING: "mentoringDisassociationLoading",
  MENTORING_REJECT_CANCEL_LOADING: "mentoringRejectCancelLoading",
};

export const OPPORTUNITIES_ACTIONS = {
  CREATE: "createOpportunity",
  UPDATE: "updateOpportunity",
  GET_UPDATE_LOADING: "getUpdateLoading",
  GET_SUBMIT_LOADING: "getSubmitLoading",
  GET_FILTERED_OPPORTUNITIES_ACTIONS: "getFilteredOpportunities",
  GET_FILTERED_OPPORTUNITIES_ACTIONS_LOADING: "getFilteredOpportunitiesLoading",
  GET_OPPORTUNITY_DETAILS_ACTIONS_LOADING: "getOpportunityDetails",
  GET_OPPORTUNITY_DETAILS_ACTIONS: "getOpportunityDetailsAction",
  UPDATE_OPPORTUNITY_STATE_ACTIONS_LOADING: "updateOpportunityStateLoading",
  GET_CANDIDATE_INFORMATION: "getCandidateInformation",
  GET_CANDIDATE_INFORMATION_LOADING: "getCandidateInformationLoading",
  GET_OPPORTUNITY_USERS_ACTIONS: "getOpportunityUsers",
  GET_OPPORTUNITY_USERS_ACTIONS_LOADING: "getOpportunityUsersLoading",
  APPROVE_CANDIDATE_LOADING: "approveOpportunityCandidateLoading",
  REJECT_CANDIDATE_LOADING: "rejectOpportunityCandidateLoading",
  GET_OPPORTUNITY_DOCUMENT: "getOpportunityDocument",
  GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES:
    "getFilteredPossibleOpportunityCandidates",
  GET_FILTERED_POSSIBLE_OPPORTUNITY_CANDIDATES_LOADING:
    "getFilteredPossibleOpportunityCandidatesLoading",
  ADD_OPPORTUNITY_CANDIDATES_LIST_LOADING:
    "addOpportunityCandidatesListLoading",
  GET_ALL_OPPORTUNITY_APPLICATIONS: "getAllOpportunityApplications",
  GET_ALL_OPPORTUNITY_APPLICATIONS_LOADING:
    "getAllOpportunityApplicationsLoading",
  GET_IS_OPPORTUNITY_APPLICATION: "getIsOpportunityApplication",
  GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP: "getAllOpportunityApplicationsFollowUp",
  GET_ALL_OPPORTUNITY_APPLICATIONS_FOLLOWUP_LOADING: "getAllOpportunityApplicationsFollowUpLoading",
  GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS: "getOpportunityApplicationsFollowUpDetails",
  GET_OPPORTUNITY_APPLICATIONS_FOLLOWUP_DETAILS_LOADING: "getOpportunityApplicationsFollowUpDetailsLoading",
  UPDATE_OPPORTUNITY_FOLLOWUP_PERIOD_LOADING: "updateOpportunityFollowupPeriod",
  GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS: "getOpportunityApplicationFollowUpsWithUnapprovedReports",
  GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_LOADING: "getOpportunityApplicationFollowUpsWithUnapprovedReportsLoading",
  GET_FEEDBACK_REPORT_DETAILS: "getFeedbackReportDetails",
  GET_FEEDBACK_REPORT_DETAILS_LOADING: "getFeedbackReportDetailsLoading",
  VALIDATE_FEEDBACK_REPORT_LOADING: "validateFeedbackReportLoading",
  CREATE_ADHOC_FEEDBACK_REPORT_LOADING: "createAdHocFeedbackReportLoading",
  CLEAR_VALIDATE_FEEDBACK_REPORT_LOADING: "clearValidateFeedbackReportLoading"
};

export const DASHBOARD_ACTIONS = {
  GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS: "getOpportunityApplicationFollowUpsWithUnapprovedReports",
  GET_OPPORTUNITY_APPLICATION_FOLLOWUPS_WITH_UNAPPROVED_REPORTS_LOADING: "getOpportunityApplicationFollowUpsWithUnapprovedReportsLoading"
};

export const REPORTS = {
  GET_REPORT: "GetReport",
  GET_REPORT_LOADING: "getReportLoading",
};
