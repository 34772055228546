const componentStyles = (theme) => ({
  container: {
    padding: "40px",
    fontSize: "25px",
    color: theme.palette.black.main,
  },
  logo: {
    width: "150px",
    marginBottom: "20px",
  },
  description: {
    marginTop: "20px",
    fontSize: "18px",
  },
});

export default componentStyles;
