import { AUTH } from "utils/actionsConsts";

const authReducer = (
  state = {
    email: "",
    loggedIn: false,
    token: "",
    login_loading: false,
    reset_password_loading: false,
    session_expired: false,
    lastLoginDate: "",
    showNews: false,
  },
  action
) => {
  switch (action.type) {
    case "register":
      return { ...state, loggedIn: action.payload };
    case "login_loading":
      return { ...state, login_loading: true };
    case "login":
      return {
        ...state,
        email: action.payload.email,
        loggedIn: action.payload.succeeded,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        login_error: !action.payload.succeeded,
        login_loading: false,
        name: action.payload.name,
        lastName: action.payload.lastName,
        province: action.payload.province,
        photo: action.payload.photo,
        operationsList: action.payload.operationsList,
        userPermissionSettings: action.payload.userPermissionSettings,
        session_expired: false,
        mustConfirmEmail: action.payload.mustConfirmEmail,
        lastLoginDate: action.payload.lastLoginDate,
      };
    case "lastLogin":
      return {
        ...state,
        lastLoginDate: action.payload.lastLoginDate,
        showNews: action.payload.showNews,
        news: action.payload.news,
      };
    case "updateProfile":
      var splitName = action.payload.name.split(" ");
      var splitSurname = action.payload.lastName.split(" ");
      return {
        ...state,
        name: splitName[0],
        lastName: splitSurname[splitSurname.length - 1],
        photo: action.payload.photo ?? state.photo,
      };
    case "logout":
      console.log("LOGOUT");
      return {
        ...state,
        loggedIn: false,
        login_error: false,
        token: "",
        login_loading: false,
        reset_password_loading: false,
      };

    case "refresh-token":
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        token: action.payload.jwtToken,
      };
    case AUTH.SESSION_EXPIRED:
      return { ...state, loggedIn: false, session_expired: true };
    case AUTH.RESET_SESSION_EXPIRED:
      return { ...state, session_expired: false };

    case AUTH.RECOVER_PASSWORD:
      return {
        ...state,
        sent: action.payload.sent,
        recover_password_loading: false,
      };
    case AUTH.RECOVER_PASSWORD_LOADING:
      return {
        ...state,
        recover_password_loading: action.payload,
      };
    case AUTH.RESET_RECOVER_STATE:
      return {
        ...state,
        sent: false,
        recover_password_loading: false,
      };

    case AUTH.RESET_PASSWORD_LOADING:
      return {
        ...state,
        reset_password_loading: action.payload,
      };
    case AUTH.RESET_PASSWORD:
      return {
        ...state,
        reset_ok: true,
        sent: false,
        reset_password_loading: false,
      };
    case AUTH.RESET_RESET_PASSWORD_STATE:
      return {
        ...state,
        reset_ok: false,
      };

    default:
      return state;
  }
};

export default authReducer;
